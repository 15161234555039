<template>
  <v-card elevation="0">
    <v-card-title>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="（キーワード検索）"
        single-line
        hide-details
        clearable
        dense
      ></v-text-field>
    </v-card-title>
    <!-- 媒体登録ダイアログ -->
    <shop-site-dialog-component
      ref="shopSiteDialog"
      @save="save"
    ></shop-site-dialog-component>
    <!-- テーブル -->
    <v-data-table
      :headers="headers"
      :items="desserts"
      :search="search"
      item-key="id"
      :mobile-breakpoint="0"
      :items-per-page="50"
      :footer-props="{
        'items-per-page-options': [50, 100, -1],
        'items-per-page-text': '表示件数',
      }"
    >
      <!-- eslint-disable-next-line -->
        <template v-slot:footer.page-text="props">
        {{ props.pageStart }} ～{{ props.pageStop }}件／全{{
          props.itemsLength
        }}件
      </template>
      <template v-slot:item.add="{ item }"> <!-- eslint-disable-line -->
        <v-icon class="pa-1" @click="addItem(item)"> mdi-plus-box </v-icon>
      </template>
      <template v-slot:item.category="{ item }"> <!-- eslint-disable-line -->
        <span
          :class="`font-weight-bold ${
            item.category == setting.site_category_a_type
              ? 'orange--text'
              : item.category == setting.site_category_b_type
              ? 'blue--text'
              : item.category == setting.site_category_c_type
              ? 'green--text'
              : item.category == setting.site_category_hp_link
              ? 'purple--text'
              : 'black--text'
          }`"
          >{{ item.category }}</span
        >
      </template>
      <template v-slot:item.content="{ item }"> <!-- eslint-disable-line -->
        <v-chip
          v-for="(content, index) in item.content"
          :key="index"
          :color="content.is_update ? 'success' : 'warning'"
          class="ma-1 font-weight-bold"
          small
        >
          {{ content.name }}{{ content.is_update ? "" : "※取込のみ" }}
        </v-chip>
      </template>
      <template v-slot:no-data> 表示するデータがありません </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { ref, reactive, toRefs, defineComponent } from "@vue/composition-api";
import store from "@/store/index.js";
import { repositoryFactory } from "@/repository/repositoryFactory";
import setting from "@/common/setting.js";
import ShopSiteDialogComponent from "./shopSiteDialog.vue";

export default defineComponent({
  components: {
    ShopSiteDialogComponent,
  },
  props: ["shop_id"],
  setup(props, ctx) {
    const SitesRepository = repositoryFactory.get("sites");
    const shopSiteDialog = ref();
    const state = reactive({
      search: "",
      headers: [
        {
          text: "追加",
          value: "add",
          sortable: false,
          align: "center",
          width: 60,
        },
        {
          text: "分類",
          value: "category",
          sortable: true,
          class: "td_category",
          align: "center",
        },
        { text: "媒体名", value: "name", sortable: true, class: "td_name" },
        {
          text: "更新対応コンテンツ",
          value: "content",
          sortable: false,
          class: "td_content",
        },
        {
          text: "タイプ",
          value: "type_name",
          sortable: true,
          class: "td_type_name",
        },
      ],
      desserts: [], // DataTablesレコード格納用
      colorNum: setting.shop_site_colors.length,
    });

    // 初期化処理(同期処理)
    const init = async () => {
      store.dispatch("loadingIcon/showIcon"); // ローディング表示
      state.desserts.splice(0);
      await SitesRepository.admin_list_with_content()
        .then((response) => {
          if (response.data) {
            Object.keys(response.data).forEach(function (key) {
              response.data[key]["type_name"] = setting.site_types.find(
                (r) => r.type == response.data[key]["type"]
              )["name"];
              state.desserts.push(response.data[key]);
            });
          }
        })
        .catch((error) => {
          throw "ERROR:Site.vue/init SitesRepository.list (" + error + ")";
        });
      store.dispatch("loadingIcon/hideIcon"); // ローディング非表示
    };
    init();

    const addItem = (site) => {
      const items = {
        id: 0,
        shop_id: props.shop_id,
        site_id: site.id,
        loginid: "",
        password: "",
        auth_url: site.default_auth_url,
        memo: "",
        color: setting.shop_site_colors[0],
        diagReadonly: false,
        category: site.category,
      };
      // // ダイアログ開く
      shopSiteDialog.value.showDialog(items);
    };

    // サイトが新規追加されたとき
    const save = () => {
      // ctx.emit("reload");
      ctx.emit("tabmove", 0);
    };

    // 返却オブジェクト定義
    return {
      setting,
      shopSiteDialog,
      ...toRefs(state),
      addItem,
      init,
      save,
    };
  },
});
</script>
<style scoped>
::v-deep .v-data-table table thead tr th,
::v-deep .v-data-table table tbody tr td {
  padding: 0 6px !important;
}
::v-deep .td_name {
  min-width: 160px;
  width: 300px;
}
::v-deep .td_type_name {
  min-width: 80px;
  width: 150px;
}
::v-deep .td_content {
  min-width: 200px;
  max-width: 200px;
}
::v-deep .td_category {
  min-width: 60px;
  width: 100px;
}
</style>
